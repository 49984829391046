import React from "react"
import Home from "../components/Home"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
   <Layout location={location}>
      <SEO
         title="Muvu - La app que te recompensa por hacer actividad física."
         description="Muvu es la app que te premia por hacer actividad física, gana puntos y canjealos por increibles premios."
      />
      <Home />
   </Layout>
)

export default IndexPage
