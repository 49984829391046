import React, { useRef } from 'react'
import { Row, Col, Button, Card, Carousel } from 'antd'
import businessPersonAvatar from '../images/businessPersonAvatar.png'

const Testimonials = () => {

   const ref = useRef()

   const handleNext = () => {
      ref.current.next()
   }

   const handlePrev = () => {
      ref.current.prev()
   }

   const testimonialCardsOne = [
      {
         fullName: 'jproj8',
         content: 'Me ha servido mucho para obtener beneficios extras y lo mejor, haciendo deporte!',
         avatar: `${businessPersonAvatar}`
      },
      {
         fullName: 'Carolinaga',
         content: 'Muy buena aplicación que me permite cambiar regalos solo por caminar o hacer ejercicios , te motiva !',
         avatar: `${businessPersonAvatar}`
      },
      {
         fullName: 'regor_dete',
         content: 'Excelente aplicación para que hacer deporte sea más divertido, el factor competencia con los compañeros de trabajo es muy motivador además de poder obtener premios haciendo ejercicio! Sería genial que esta aplicación fuera no solo para empresas sino también para poder utilizarlo con mi familia.',
         avatar: `${businessPersonAvatar}`
      }
   ]

   const testimonialCardsTwo = [
      {
         fullName: 'Maida1277',
         content: 'Una aplicación muy buena, ayuda mucho a las personas sedentarias y lo mejor de todo que te dan recompensas 👏🏻👏🏻',
         avatar: `${businessPersonAvatar}`
      },
      {
         fullName: 'Mario P. G',
         content: 'La app es buenísima, permite integración con otras app de vida, salud y deporte y además, te entrega premios y beneficios por los puntos que acumulas, solo caminando o corriendo (yo corro) 💪🏼',
         avatar: `${businessPersonAvatar}`
      }
   ]

   return (
      <Row justify="center" className="testimonials">
         <Col lg={24} xs={24} className="testimonials-wrapper">
            <Row justify="center">
               <Col lg={24} xs={24} className="testimonials-wrapper__background">
                  <Row justify="center" className="testimonials-content">
                     <Col lg={24} xs={22}>
                        <h3>Testimonios de nuestros usuarios</h3>
                     </Col>
                     <Col lg={22} xs={22} className="testimonials-content__desktop">
                        <Row justify="space-between" align="middle">
                           <Col lg={2} className="control-button-wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
                              {
                                 !testimonialCardsTwo.length ?
                                    <div />
                                    :
                                    <Button size="large" onClick={handlePrev}>
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 539.24 956.13"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path d="M521,938.26A61,61,0,0,0,521,852L147.27,478.24l374.1-374.11A61,61,0,0,0,435.1,17.87L18.21,434.75l-.17.18-.17.17a61,61,0,0,0,0,86.27L434.76,938.26A61,61,0,0,0,521,938.26Z" /></g></g></svg>
                                    </Button>
                              }
                           </Col>
                           <Col lg={20}>
                              <Carousel ref={ref} dots={false}>
                                 <Row>
                                    <Col lg={24}>
                                       <Row gutter={15}>
                                          {
                                             testimonialCardsOne.map((testimonial, index) => {
                                                return (
                                                   <Col lg={8} key={index}>
                                                      <Card
                                                         style={{ paddingTop: 10, paddingBottom: 10, borderRadius: 15 }}
                                                      >
                                                         <Row justify="center">
                                                            <Col lg={22}>
                                                               <p className="testimonial-name">{testimonial.fullName}</p>
                                                            </Col>
                                                            <Col lg={22}>
                                                               <p className="testimonial-content">"{testimonial.content}"</p>
                                                            </Col>
                                                         </Row>
                                                      </Card>
                                                   </Col>
                                                )
                                             })
                                          }
                                       </Row>
                                    </Col>
                                 </Row>
                                 <Row>
                                    <Col lg={24}>
                                       <Row gutter={15}>
                                          {
                                             testimonialCardsTwo.map((testimonial, index) => {
                                                return (
                                                   <Col lg={8} key={index}>
                                                      <Card
                                                         style={{ paddingTop: 10, paddingBottom: 10, borderRadius: 15, minHeight: 240 }}
                                                      >
                                                         <Row justify="center">
                                                            <Col lg={22}>
                                                               <p className="testimonial-name">{testimonial.fullName}</p>
                                                            </Col>
                                                            <Col lg={22}>
                                                               <p className="testimonial-content">"{testimonial.content}"</p>
                                                            </Col>
                                                         </Row>
                                                      </Card>
                                                   </Col>
                                                )
                                             })
                                          }

                                       </Row>
                                    </Col>
                                 </Row>
                              </Carousel>
                           </Col>
                           <Col lg={2} className="control-button-wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
                              {
                                 !testimonialCardsTwo.length ?
                                    <div />
                                    :
                                    <Button size="large" onClick={handleNext}>
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 539.24 956.13"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path d="M18.21,17.87a61,61,0,0,0,0,86.26L392,477.89,17.87,852a61,61,0,0,0,86.26,86.27L521,521.37l.17-.18.18-.17a61,61,0,0,0,0-86.26L104.48,17.87A61,61,0,0,0,18.21,17.87Z" /></g></g></svg>
                                    </Button>
                              }
                           </Col>
                        </Row>
                     </Col>
                     <Col md={24} sm={24} xs={24} className="testimonials-content__mobile">
                        <div className="cards-container">
                           {
                              testimonialCardsOne.map((testimonial, index) => {
                                 return (
                                    <Col md={14} sm={14} xs={19} key={index}>
                                       <Card
                                          style={{ paddingTop: 5, paddingBottom: 5, borderRadius: 15, minHeight: 240 }}
                                       >
                                          <Row justify="center">
                                             <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <img src={testimonial.avatar} width="88" height="88" alt="avatar" />
                                             </Col>
                                             <Col xs={24}>
                                                <p className="testimonial-name">{testimonial.fullName}</p>
                                             </Col>
                                             <Col xs={24}>
                                                <p className="testimonial-content">"{testimonial.content}"</p>
                                             </Col>
                                          </Row>
                                       </Card>
                                    </Col>
                                 )
                              })
                           }
                        </div>
                     </Col>
                  </Row>
               </Col>
            </Row>
         </Col>
      </Row>
   )
}

export default Testimonials