import React, { useState } from 'react'
import { Row, Col, Steps, Button, Collapse } from 'antd'
import Fitbit from '../images/appsImages/Fitbit.jpg'
import HuaweiHealth from '../images/appsImages/HuaweiHealth.jpg'
import Salud from '../images/appsImages/Salud.jpg'
import Garmin from '../images/appsImages/Garmin.jpg'
import GoogleFit from '../images/appsImages/GoogleFit.jpg'
import Strava from '../images/appsImages/Strava.jpg'
import Runkeeper from '../images/appsImages/Runkeeper.jpg'
import Pacer from '../images/appsImages/Pacer.jpg'
import { isMobile } from 'react-device-detect'

const { Step } = Steps
const { Panel } = Collapse

const BenefitSteps = () => {
   const [currentStep, setCurrentStep] = useState(0)

   const nextStep = () => {
      setCurrentStep(currentStep + 1)
   }

   const prevStep = () => {
      setCurrentStep(currentStep - 1)
   }

   const steps = [
      {
         title: 'Conecta',
         content: (
            <Col lg={24}>
               <Row justify="center">
                  <Col lg={24}>
                     <h4 style={{ textAlign: 'center' }}>Conecta Muvu a tu app de actividad física favorita</h4>
                  </Col>
                  <Col lg={24} style={{ marginTop: 30 }}>
                     <Row gutter={[30, 30]}>
                        <Col lg={6} xs={12}>
                           <Row justify="center">
                              <Col lg={24} xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                 <img src={Fitbit} width="88" height="88" alt="Fitbit icono" />
                              </Col>
                              <Col lg={24} xs={24}>
                                 <h5 style={{ textAlign: 'center' }}>Fitbit</h5>
                              </Col>
                           </Row>
                        </Col>
                        <Col lg={6} xs={12}>
                           <Row justify="center">
                              <Col lg={24} xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                 <img src={HuaweiHealth} width="88" height="88" alt="Huawei icono" />
                              </Col>
                              <Col lg={24} xs={24}>
                                 <h5 style={{ textAlign: 'center' }}>Huawei Health</h5>
                              </Col>
                           </Row>
                        </Col>
                        <Col lg={6} xs={12}>
                           <Row justify="center">
                              <Col lg={24} xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                 <img src={Salud} width="88" height="88" alt="Salud icono" />
                              </Col>
                              <Col lg={24} xs={24}>
                                 <h5 style={{ textAlign: 'center' }}>Salud</h5>
                              </Col>
                           </Row>
                        </Col>
                        <Col lg={6} xs={12}>
                           <Row justify="center">
                              <Col lg={24} xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                 <img src={Garmin} width="88" height="88" alt="Garmin icono" />
                              </Col>
                              <Col lg={24} xs={24}>
                                 <h5 style={{ textAlign: 'center' }}>Garmin</h5>
                              </Col>
                           </Row>
                        </Col>
                        <Col lg={6} xs={12}>
                           <Row justify="center">
                              <Col lg={24} xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                 <img src={GoogleFit} width="88" height="88" alt="Google fit icono" />
                              </Col>
                              <Col lg={24} xs={24}>
                                 <h5 style={{ textAlign: 'center' }}>Google Fit</h5>
                              </Col>
                           </Row>
                        </Col>
                        <Col lg={6} xs={12}>
                           <Row justify="center">
                              <Col lg={24} xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                 <img src={Strava} width="88" height="88" alt="Strava icono" />
                              </Col>
                              <Col lg={24} xs={24}>
                                 <h5 style={{ textAlign: 'center' }}>Strava</h5>
                              </Col>
                           </Row>
                        </Col>
                        <Col lg={6} xs={12}>
                           <Row justify="center">
                              <Col lg={24} xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                 <img src={Runkeeper} width="88" height="88" alt="Runkeeper icono" />
                              </Col>
                              <Col lg={24} xs={24}>
                                 <h5 style={{ textAlign: 'center' }}>Runkeeper</h5>
                              </Col>
                           </Row>
                        </Col>
                        <Col lg={6} xs={12}>
                           <Row justify="center">
                              <Col lg={24} xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                 <img src={Pacer} width="88" height="88" alt="Pacer icono" />
                              </Col>
                              <Col lg={24} xs={24}>
                                 <h5 style={{ textAlign: 'center' }}>Pacer</h5>
                              </Col>
                           </Row>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Col>
         )
      },
      {
         title: 'Desafíate',
         content: (
            <Col lg={24} xs={24}>
               <Row justify="center">
                  <Col lg={24} xs={24}>
                     <h4 style={{ textAlign: 'center' }}>Mantente en movimiento</h4>
                  </Col>
                  <Col lg={24} xs={24}>
                     <Row justify="space-between" align="middle">
                        <Col lg={{span: 6, order: 1}} xs={{span: 24 , order: 2}} style={{ display: 'flex', justifyContent: 'center' }}>
                           <img src="https://via.placeholder.com/240x207" alt="image" />
                        </Col>
                        <Col className="text-wrapper" lg={{span: 16, order: 2}} xs={{span: 24, order: 1}}>
                           <p>Compite contigo mismo definiendo una meta de actividad semanal y participa en geniales desafíos para ganar más. No necesitas ser un súper atleta: puedes caminar, bailar, subir escaleras, salir a pasear a tu perrito ¡Lo que quieras con tal de estar en movimiento!</p>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Col>
         )
      },
      {
         title: 'Hazlo social',
         content: (
            <Col lg={24} xs={24}>
               <Row justify="center">
                  <Col lg={24} xs={24}>
                     <h4 style={{ textAlign: 'center' }}>Motívate compitiendo con tus amigos</h4>
                  </Col>
                  <Col lg={24} xs={24}>
                     <Row justify="space-between">
                        <Col lg={{span: 6, order: 1}} xs={{span: 24 , order: 2}} style={{ display: 'flex', justifyContent: 'center' }}>
                           <img src="https://via.placeholder.com/240x207" alt="image" />
                        </Col>
                        <Col className="text-wrapper" lg={{span: 16, order: 2}} xs={{span: 24, order: 1}}>
                           <p>Hazlo social, invita a tus amigos y sigue de cerca sus movimientos en el ranking de "seguidos". ¡La competencia se vuelve muy entretenida!</p>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Col>
         )
      },
      {
         title: 'Canjea premios',
         content: (
            <Col lg={24} xs={24}>
               <Row justify="center">
                  <Col lg={24} xs={24}>
                     <h4 style={{ textAlign: 'center' }}>Gana por mantener una vida más saludable</h4>
                  </Col>
                  <Col lg={24} xs={24}>
                     <Row justify="space-between">
                        <Col lg={{span: 6, order: 1}} xs={{span: 24 , order: 2}} style={{ display: 'flex', justifyContent: 'center' }}>
                           <img src="https://via.placeholder.com/240x207" alt="image" />
                        </Col>
                        <Col className="text-wrapper" lg={{span: 16, order: 2}} xs={{span: 24, order: 1}}>
                           <p>Aprovecha nuestro completo catálogo de tiendas con premios y descuentos a lo largo de todo Chile. Todas las semanas incorporamos nuevas alianzas para ti.</p>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Col>
         )
      }
   ]

   const customDot = (dot, { status, index }) => {
      return (
         <div className={index === currentStep ? "custom-dot-active" : "custom-dot"}>
            {index + 1}
         </div>
      )
   }


   return (
      <React.Fragment>
         {
            !isMobile ?

               <Row justify="center" className="benefit-steps-container" style={{ paddingTop: 80 }}>
                  <Col lg={18}>
                     <h3 style={{ textAlign: 'center', marginLeft: 25 }}>¡Descarga Muvu, gana premios y mucho más!</h3>
                  </Col>
                  <Col lg={18}>
                     <Steps current={currentStep} labelPlacement="vertical" progressDot={customDot}>
                        {steps.map(item => (
                           <Step key={item.title} title={item.title} />
                        ))}
                     </Steps>
                  </Col>
                  <Col lg={20} style={{ marginTop: 50 }} >
                     <Row justify="space-between" align="middle" style={{ height: 400 }}>
                        <Col lg={2} >
                           {
                              currentStep === 0 ?
                                 <div />
                                 :
                                 <Button size="large" onClick={() => prevStep()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 539.24 956.13"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path d="M521,938.26A61,61,0,0,0,521,852L147.27,478.24l374.1-374.11A61,61,0,0,0,435.1,17.87L18.21,434.75l-.17.18-.17.17a61,61,0,0,0,0,86.27L434.76,938.26A61,61,0,0,0,521,938.26Z" /></g></g></svg>
                                 </Button>
                           }
                        </Col>
                        <Col xl={17} lg={18}>
                           <Row justify="center">
                              {steps[currentStep].content}
                           </Row>
                        </Col>
                        <Col lg={2} >
                           {
                              currentStep === 3 ?
                                 <div />
                                 :
                                 <Button size="large" onClick={() => nextStep()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 539.24 956.13"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path d="M18.21,17.87a61,61,0,0,0,0,86.26L392,477.89,17.87,852a61,61,0,0,0,86.26,86.27L521,521.37l.17-.18.18-.17a61,61,0,0,0,0-86.26L104.48,17.87A61,61,0,0,0,18.21,17.87Z" /></g></g></svg>
                                 </Button>
                           }
                        </Col>
                     </Row>
                  </Col>
               </Row>
               :
               <Row justify="center" className="benefit-collapse-container" style={{ paddingTop: 80 }}>
                  <Col lg={18} xs={22}>
                     <h3>¡Descarga Muvu, gana premios y mucho más!</h3>
                  </Col>
                  <Col xs={22}>
                     <Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost bordered={false}>
                        {
                           steps.map((step, index) => {
                              return (
                                 <Panel header={step.title} key={index + 1}>
                                    {step.content}
                                 </Panel>
                              )
                           })
                        }
                     </Collapse>
                  </Col>
               </Row>

         }
      </React.Fragment>
   )
}

export default BenefitSteps