import React, { useState } from 'react'
import { Row, Col,Button } from 'antd'
import AppStoreButton from '../images/AppStoreButton.png'
import GooglePlayButton from '../images/GooglePlayButton.png'
import HomePresentation from '../images/HomePresentation.png'
import Presentation from './Presentation'
import BenefitSteps from './BenefitSteps'
import Stores from './Stores'
import Testimonials from './Testimonials'
import * as _ from 'lodash'

const Home = () => {
   const [visible, setVisible] = useState(false);

   const showDrawer = () => {
      setVisible(true)
   }

   const onClose = () => {
      setVisible(false)
   }

   const text = (
      <Row>
         <h1 style={{ fontWeight: 'bold' }}>¡Muévete y gana!</h1>
         <h2>Camina, baila, practica deportes, ¡lo que quieras con tal de <strong style={{ fontWeight: 'bold' }}> estar en movimiento</strong>! Esto te permitirá <strong style={{ fontWeight: 'bold' }}>ganar puntos</strong> para canjear  <strong style={{ fontWeight: 'bold' }}>descuentos y premios</strong> de tus tiendas favoritas. <br></br> <br></br>¡Participa en <strong style={{ fontWeight: 'bold' }}>desafíos</strong>, crea un <strong style={{ fontWeight: 'bold' }}>ranking</strong> con tus amigos, <strong style={{ fontWeight: 'bold' }}>desafíate</strong> día a día y mantén tu <strong style={{ fontWeight: 'bold' }}>motivación</strong> a tope con Muvu!
         </h2>
      </Row> 
   )
   
   const socialButtons = (
      <Row justify="start" className="social-buttons-wrapper">
         <Col style={{ marginRight: 15}}>
            <a href="https://onelink.to/47atrw" target="_blank">
               <img src={AppStoreButton} height="40" width="130" alt="Botón App Store" />
            </a>
         </Col>
         <Col>
            <a href="https://onelink.to/47atrw" target="_blank">
               <img src={GooglePlayButton} height="40" width="130" alt="Botón Play Store" />
            </a>
         </Col>
      </Row>
   )

   return (
      <Row justify="center">
         <Col lg={24} xs={24}>
            <Presentation text={text} socialButtons={socialButtons} image={HomePresentation} />
         </Col>
         <Col lg={24} xs={24}>
            <BenefitSteps />
         </Col>
         <Col lg={24} xs={24} className="companies-landing__stores">
            <Stores />
         </Col>
         <Col lg={24} xs={24}>
            <Testimonials />
         </Col>
      </Row>
   )
}

export default Home